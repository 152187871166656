import { makeRequest } from './request';

export default Vue.component('waitlist', {
    template: 'waitlist',
    props: {
        routes: String,
        modal: Boolean,
        close: Function,
    },
    data() {
        return {
            step: 0,
            error: false,
            email: '',
            readyRadio: '',
            readyInput: '',
            pricingRadio: '',
            pricingInput: '',
            currentRoutes: null as null | Record<string, any>,
        };
    },
    beforeMount() {
        this.currentRoutes = JSON.parse(this.routes) as any;
        this.sendForm(this.currentRoutes?.visit);
    },
    computed: {
        nextButton() {
            const { step, readyInput, pricingInput } = this as any;
            return (step === 1 && readyInput) || (step === 2 && pricingInput);
        },
    },
    methods: {
        closeNow() {
            this.close();
        },
        sendForm(uri: string, params = {}, nextStep = false) {
            makeRequest(uri, 'POST', params)
                .then((response) => {
                    if (response.ok && nextStep) {
                        this.step += 1;
                    }
                });
        },
        nextStep() {
            const { readyRadio, readyInput, pricingRadio, pricingInput } = this;
            if (this.step === 1 && (readyRadio || readyInput)) {
                const params = {
                    answer: readyRadio || readyInput,
                };
                this.sendForm(this.currentRoutes?.q1, params, true);
            }
            if (this.step === 2 && (pricingRadio || pricingInput)) {
                const params = {
                    answer: pricingRadio || pricingInput,
                };
                this.sendForm(this.currentRoutes?.q2, params, true);
            }
        },
        emailValidation() {
            const email = this.email.trim();
            const re = /\S+@\S+\.\S+/;

            if (email.length > 0) {
                if (re.test(email)) {
                    const params = {
                        email,
                    };
                    this.sendForm(this.currentRoutes?.waitlist, params, true);
                } else {
                    this.error = true;
                }
            }
        },
    },
});

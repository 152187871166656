import Parser from 'ua-parser-js';
import { OS_NAMES, BROWSER_NAMES } from './consts'; // eslint-disable-line import/no-unresolved

const UNKNOWN_MARK = 'unknown';
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const EDGE_REGEX = /(edge|edgios|edga|edg)\/((\d+)?[\w.]+)/i;

export const getBrowserName = () => {
    try {
        if (window.navigator
            && window.navigator.userAgent && EDGE_REGEX.test(window.navigator.userAgent)) {
            return BROWSER_NAMES.EDGE;
        }

        return String(new Parser().getBrowser().name || UNKNOWN_MARK).toLowerCase();
    } catch (error) {
        return UNKNOWN_MARK;
    }
};

export const getOSName = () => {
    try {
        const osName = String(new Parser().getOS().name || UNKNOWN_MARK).toLowerCase();
        if (osName === OS_NAMES.MAC_OS && window.navigator.maxTouchPoints > 1) {
            return OS_NAMES.IOS;
        }

        return osName;
    } catch (error) {
        return UNKNOWN_MARK;
    }
};

export const getOSVersion = () => {
    try {
        return String(new Parser().getOS().version || UNKNOWN_MARK).toLowerCase();
    } catch (error) {
        return UNKNOWN_MARK;
    }
};

export const isValidEmail = (email: string) => EMAIL_REGEX.test(email);

export const prepareFormUrlParams = (body: any) => new URLSearchParams(body).toString();

import 'vue';
import '../common/main.pcss';
import './pcss/main.pcss';
import waitlist from './components/waitlist';

Vue.config.devtools = true;

new Vue({ // eslint-disable-line no-new
    el: '#app',
    delimiters: ['${', '}'],
    data() {
        return {
            isJoinModal: false,
        };
    },
    components: {
        waitlist,
    },
    methods: {
        openJoinModal() {
            (this as any).isJoinModal = true;

            window.addEventListener('keyup', (event) => {
                if (event.key === 'Escape' || event.key === 'Esc') {
                    (this as any).isJoinModal = false;
                }
            });
        },

        closeJoinModal() {
            (this as any).isJoinModal = false;
        },
    },
});

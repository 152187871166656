import 'whatwg-fetch';

import { prepareFormUrlParams } from './helpers';

type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

export const makeRequest = (
    path: string,
    method: MethodType,
    parameters: Record<string, string> = {},
    asJson = false,
) => {
    // Throw error for GET request with body
    if (method === 'GET' && asJson) {
        throw new Error();
    }

    let url = path;

    const config: Record<string, any> = {
        method,
        headers: {
            'x-fetch': '1',
        },
        body: '',
    };

    if (method === 'GET') {
        const urlObj = new URL(path);
        Object.entries(parameters)
            .forEach(([key, value]) => urlObj.searchParams.append(key, String(value)));
        url = urlObj.href;
    } else if (asJson) {
        config.body = JSON.stringify(parameters);
        config.headers['Content-Type'] = 'application/json';
    } else {
        config.body = prepareFormUrlParams(parameters);
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    return window
        .fetch(url, config)
        .then(async (response) => {
            const data = await response.json();

            if (response.ok) {
                return data;
            }

            return Promise.reject(data);
        });
};
